<template>
  <svg
    width="64px"
    height="42.6px"
    viewBox="0 4.008 64 42.613"
    xmlns="http://www.w3.org/2000/svg"
  >
    <defs>
      <linearGradient
        id="apos-upload-gradient"
        gradientUnits="userSpaceOnUse"
        x1="12"
        y1="4"
        x2="12"
        y2="20"
        gradientTransform="matrix(1.848483, 1.922034, -2.33985, 2.250314, 31.445274, -24.099256)"
      >
        <stop offset="0" style="stop-color: rgb(50, 197, 255);" />
        <stop offset="0.337" style="stop-color: rgb(182, 32, 224);" />
        <stop offset="0.501" style="stop-color: rgb(182, 32, 224);" />
        <stop offset="0.854" style="stop-color: rgb(247, 181, 0);" />
        <stop offset="1" style="stop-color: rgb(247, 181, 0);" />
      </linearGradient>
    </defs>
    <path d="M 37.334 28 L 37.334 38.666 L 26.666 38.666 L 26.666 28 L 18.666 28 L 32 14.666 L 45.334 28 M 51.599 20.079 C 49.787 10.907 41.706 4 32 4 C 24.293 4 17.6 8.374 14.267 14.747 C 6.24 15.626 0 22.4 0 30.666 C 0 39.503 7.164 46.666 16 46.666 L 50.666 46.666 C 58.03 46.666 64 40.697 64 33.334 C 64 26.294 58.533 20.586 51.599 20.079 Z" />
  </svg>
</template>

<style lang="scss" scoped>
  // .apos-cloud-icon--gradient {
  //   fill: url(#apos-cloud-icon__gradient);
  // }
</style>
